import React, { useState } from 'react'

import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { strengthIndicator, strengthColor } from '../utility/password-strength'

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: (props) => props.color,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: (props) => props.color,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: (props) => props.color,
    },
  },
})

const ConfirmPasswordInput = ({ value, placeholder, handleConfirmChanges }) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const strength = strengthIndicator(value)
  const color = strengthColor(strength)

  const props = {
    color: color,
  }

  const classes = useStyles(props)

  const handleConfirmPassword = (strength, color) => {
    handleConfirmChanges(strength, color)
  }

  return (
    <TextField
      variant='outlined'
      type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
      onChange={(strength, color) => handleConfirmPassword(strength, color)}
      placeholder={placeholder}
      className={classes.root}
      fullWidth
      autoComplete='off'
      InputProps={{
        // <-- This is where the toggle button is added.
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default ConfirmPasswordInput
