import React, { useState, useEffect } from 'react'
import uploadLogo from '../../assets/images/upload-image.png'

const UploadProfilePictureContainer = ({ current_user }) => {
  const [initial, setInitial] = useState('')
  const [modalSwitch, setModalSwitch] = useState(false)
  const [profileImage, setProfileImage] = useState({
    image: null,
  })

  const handleSwitch = () => {
    setModalSwitch(!modalSwitch)
  }

  const handleModalEvent = () => {
    if (modalSwitch === true) {
      document.getElementById('myModal').showModal()
    } else {
      document.getElementById('myModal').close()
    }
  }

  const handleSetImage = () => {
    console.log(profileImage.image)

    handleSwitch()

    handleSwitch()

    setModalSwitch(!modalSwitch)
  }

  const handleImageUpload = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        setProfileImage({
          image: e.target.result,
        })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    setInitial(current_user.email.charAt(0).toUpperCase())
    handleModalEvent()
  }, [modalSwitch])

  return (
    <div>
      <div className='relative flex justify-center items-center'>
        {profileImage.image ? (
          <div>
            <div className='flex flex-row justify-center items-center w-181px h-181px bg-blue-10 text-center rounded-full text-91px text-blue-650'>
              <img
                id='target'
                src={profileImage.image}
                className='w-181px h-181px rounded-full'
              />
            </div>
            <div
              className='absolute flex flex-col justify-center items-center top-0 z-10 opacity-0 w-181px h-181px hover:opacity-90 duration-700 ease-in-out cursor-pointer bg-gray-900 rounded-full'
              onClick={handleSwitch}>
              <img
                src={uploadLogo}
                alt='upload-image-logo'
                className='relative z-20 w-40px h-28px'
              />
              <div className='relative z-50 opacity-100 text-white font-bold'>
                Upload Image
              </div>
              <div className='relative z-50 opacity-100 text-white font-bold'>
                (512px by 512px)
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className='flex flex-row justify-center items-center w-181px h-181px bg-blue-10 text-center rounded-full text-91px text-blue-650'>
              {initial}
            </div>
            <div
              className='absolute flex flex-col justify-center items-center top-0 z-10 opacity-0 w-181px h-181px hover:opacity-90 duration-700 ease-in-out cursor-pointer bg-gray-900 rounded-full'
              onClick={handleSwitch}>
              <img
                src={uploadLogo}
                alt='upload-image-logo'
                className='relative z-20 w-40px h-28px'
              />
              <div className='relative z-50 opacity-100 text-white font-bold'>
                Upload Image
              </div>
              <div className='relative z-50 opacity-100 text-white font-bold'>
                (512px by 512px)
              </div>
            </div>
          </div>
        )}

        <dialog
          id='myModal'
          className='xl:w-5/12 sm:w-4/12 p-5 h-50 bg-white rounded-md'>
          <div className='flex flex-col w-full h-auto '>
            <div className='flex flex-col w-full h-auto justify-center items-center'>
              <div className='flex flex-row justify-between items-center w-full'>
                <div className='flex justify-center items-center text-2xl font-bold text-blue-650'>
                  Upload a Profile Picture
                </div>
                <div
                  className='relative z-50 flex w-1/12 h-auto justify-center cursor-pointer'
                  onClick={handleSwitch}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#000000'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-x'>
                    <line x1='18' y1='6' x2='6' y2='18'></line>
                    <line x1='6' y1='6' x2='18' y2='18'></line>
                  </svg>
                </div>
              </div>

              <div
                className='flex flex-row justify-center items-center mt-30px mb-30px w-181px h-181px bg-blue-10 text-center
                    rounded-full text-91px text-blue-650'>
                {profileImage.image ? (
                  <img id='target' src={profileImage.image} />
                ) : (
                  initial
                )}
              </div>
            </div>

            <div className='flex w-full h-auto py-10 px-2 justify-center items-center bg-gray-200 border-gray-150 rounded text-center text-gray-500'>
              <input
                type='file'
                name='user[avatar_image]'
                accept='image/png, image/jpeg'
                value={current_user.avatar_image}
                onChange={(e) => handleImageUpload(e)}
                className='cursor-pointer'
              />
            </div>
          </div>
          <div className='flex flex-row justify-center items-center p-8'>
            <button
              className='flex justify-center items-center w-160px h-45px bg-blue-750 text-white rounded-sm'
              type='submit'
              onClick={handleSetImage}>
              Use Picture
            </button>
          </div>
        </dialog>
      </div>

      <div className='flex flex-col justify-center items-center'>
        <div className='pt-22px text-24px font-weight-600'>Member</div>
        <div className='pt-10px text-16px text-black-50'>
          {current_user.email}
        </div>
        <div className='pt-10'>
          <button className='w-150px h-45px border-blue-650 font-roboto font-normal text-blue-650'>
            <a href='/user/{current_user.id}/edit'>Change Password</a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadProfilePictureContainer
