// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

// Action Text
require("trix")
require("@rails/actiontext")

// Tailwind CSS
import 'stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

function hideNotice() {
  setInterval(function  () {
    if(document.querySelector('.notification')) {
      document.querySelector(".notification").style.display = "none";
    }
  }, 5000)
}

hideNotice()

document.addEventListener("DOMContentLoaded", function() {
  const seeMoreLinks = document.querySelectorAll(".js-see-more");
    [...seeMoreLinks].forEach((el) => {
      el.addEventListener("click", (e) => {
        const cardContainer = e.currentTarget.closest('.perks-card-container');
        const parentContainer = e.currentTarget.parentElement;
        cardContainer.classList.remove('xl:h-64');

        [...parentContainer.children].forEach((c) => {
          c.classList.toggle('hidden');
        })
      });
    });
})
