const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value)
}

const hasSpecial = (value) => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value)
}

const hasUpperCase = (value) => {
  return new RegExp(/[A-Z]/).test(value)
}

const hasLowerCase = (value) => {
  return new RegExp(/[a-z]/).test(value)
}

export const strengthColor = (count) => {
  if (count < 2) return '#B91C1C'

  if (count < 3) return '#FF7733'

  if (count < 4) return '#FFBE33'

  if (count < 5 || count > 5) return '#13B934'
}

export const strengthIndicator = (value) => {
  let strengths = 0

  if (value.length > 5) strengths++

  if (value.length > 7) strengths++

  if (hasNumber(value)) strengths++

  if (hasSpecial(value)) strengths++

  if (hasLowerCase(value)) strengths++

  if (hasUpperCase(value)) strengths++

  return strengths
}
