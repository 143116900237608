import React, { useState, useEffect } from 'react'

import validator from 'validator'

import PasswordInput from './SignUpComponents/passwordInput'
import ConfirmPasswordInput from './SignUpComponents/confirmPasswordInput'

import { strengthIndicator, strengthColor } from './utility/password-strength'

const SignUpComponent = ({ user }) => {
  const token = document.querySelector('meta[name="csrf-token"]').content
  const [email, setEmail] = useState(user.email || '')
  const [validEmail, setValidEmail] = useState(false)
  const [emailMsg, setEmailMsg] = useState('')
  const [password, setPassword] = useState(user.email || '')
  const [pwStrength, setPwStrength] = useState('No input')
  const [confirmPassword, setConfirmPassword] = useState(user.email || '')
  const [confirmPwMsg, setConfirmPwMsg] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [signupValid, setSignupValid] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordStrengthColor, setPasswordStrengthColor] = useState('')
  const [confirmPasswordStrengthColor, setConfirmPasswordStrengthColor] =
    useState('')

  const [conditionOne, setConditionOne] = useState(false)
  const [conditionTwo, setConditionTwo] = useState(false)
  const [conditionThree, setConditionThree] = useState(false)
  const [conditionFour, setConditionFour] = useState(false)
  const [conditionFive, setConditionFive] = useState(false)

  const [hasUpperCase, setHasUpperCase] = useState(false)
  const [hasLowerCase, setHasLowerCase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSpecialChar, setHasSpecialChar] = useState(false)

  const regexUpperCase = /[A-Z]/
  const regexLowerCase = /[a-z]/
  const regex = /\d/
  const specials = /\W/

  const evalEmail = (email, password, confirmPassword) => {
    if (email) {
      if (password === '') {
        setValidPassword(false)
        setSignupValid(false)
      }
      if (confirmPassword === '') {
        setValidPassword(false)
        setSignupValid(false)
      }

      if (password === '' && confirmPassword === '') {
        setValidPassword(false)
        setSignupValid(false)
      }
    }
  }

  const handleValidateEmail = (e) => {
    const email = e.target.value
    if (validator.isEmail(email)) {
      setEmail(email)
      setEmailMsg('Email is valid')
      setValidEmail(true)
    } else {
      setEmailMsg('Please enter a valid email')
      setValidEmail(false)
      setSignupValid(false)
    }
  }

  const checkStrength = (password) => {
    const str = strengthIndicator(password)
    const clr = strengthColor(str)

    setHasNumber(regex.test(password))

    setHasLowerCase(regexLowerCase.test(password))

    setHasUpperCase(regexUpperCase.test(password))

    setHasSpecialChar(specials.test(password))

    if (password.length > 7) {
      setConditionOne(true)
    } else {
      setConditionOne(false)
    }

    if (hasNumber) {
      setConditionTwo(true)
    } else {
      setConditionTwo(false)
    }

    if (hasSpecialChar) {
      setConditionThree(true)
    } else {
      setConditionThree(false)
    }

    if (hasUpperCase) {
      setConditionFour(true)
    } else {
      setConditionFour(false)
    }

    if (hasLowerCase) {
      setConditionFive(true)
    } else {
      setConditionFive(false)
    }

    if (str === 1) {
      return (
        setPasswordStrength(str),
        setPwStrength('Weak'),
        setPasswordStrengthColor(clr)
      )
    }

    if (str === 2) {
      return (
        setPasswordStrength(str),
        setPwStrength('Fair'),
        setPasswordStrengthColor(clr)
      )
    }

    if (str === 3) {
      return (
        setPasswordStrength(str),
        setPwStrength('Good'),
        setPasswordStrengthColor(clr)
      )
    }

    if (str === 4) {
      return (
        setPasswordStrength(str),
        setPwStrength('Strong'),
        setPasswordStrengthColor(clr)
      )
    }

    if (str > 5) {
      return (
        setPasswordStrength(str),
        setPwStrength('Very Strong'),
        setPasswordStrengthColor(clr)
      )
    }
  }

  const handlePasswordChanges = (e) => {
    const pw = e.target.value
    setPassword(pw)
    //document.getElementById('hiddenPassword').value = pw
  }

  const handleConfirmPasswordChanges = (e) => {
    const confirmPw = e.target.value
    setConfirmPassword(confirmPw)
  }

  const handleEqualPasswords = (password, confirmPassword) => {
    if (
      password === confirmPassword &&
      password.length > 7 &&
      confirmPassword.length > 7
    ) {
      setConfirmPasswordStrengthColor(passwordStrengthColor)
      setValidPassword(true)
    } else if (password === '' || confirmPassword === '') {
      setValidPassword(false)
    } else if (password !== confirmPassword) {
      setValidPassword(false)
    }
  }

  const checkValidSignup = () => {
    if (validEmail === false && validPassword === true) {
      setSignupValid(false)
    }
    if (validEmail === true && validPassword === false) {
      setSignupValid(false)
    }
    if (validEmail === true && validPassword === true) {
      setSignupValid(true)
    } else {
      setSignupValid(false)
    }
  }

  useEffect(() => {
    evalEmail(email, password, confirmPassword)
    checkStrength(password)
    handleEqualPasswords(password, confirmPassword)
    checkValidSignup(signupValid)
    if (password === '') {
      setValidPassword(false)
    }
    if (confirmPassword === '') {
      setValidPassword(false)
    }
    if (validEmail === true && validPassword === false) {
      setSignupValid(false)
    }
  }, [
    email,
    validEmail,
    password,
    confirmPassword,
    validPassword,
    conditionOne,
    conditionTwo,
    conditionThree,
    conditionFour,
    conditionFive,
    hasNumber,
    hasSpecialChar,
    hasLowerCase,
    hasUpperCase,
    signupValid,
  ])

  return (
    <div className='flex justify-center items-center w-screen'>
      <div className='register flex flex-col xxs:w-11/12 sm:w-3/5 lg:w-1/2 xxl:w-5/12 xxxl:max-w-xl h-736px mb-14 xxs:mt-5 sm:mt-14 xxs:px-4 sm:px-10 border-gray-15 bg-white font-roboto shadow-xl rounded-lg'>
        <h1 className='pt-12 text-blue-750 xxs:text-xl sm:text-2xl'>
          Register to Ayala Member Portal
        </h1>
        <div>
          <form
            acceptCharset='UTF-8'
            action='/users'
            method='post'
            className='flex flex-col pt-30px'>
            <input type='hidden' name='authenticity_token' value={token} />

            <label htmlFor='email'>Email</label>
            <input
              className='h-50px pt-5px border-blue-50'
              name='user[email]'
              type='email'
              autoComplete='off'
              value={email}
              onChange={(e) => {
                handleValidateEmail(e)
                setEmail(e.target.value)
              }}
            />
            <span
              className={`${
                validEmail ? 'text-green-450' : 'text-red-700'
              } delay-75`}>
              {email === '' ? 'Please enter valid email' : emailMsg}
            </span>

            <label className='pb-5px pt-20px' htmlFor='password'>
              Password
            </label>
            <PasswordInput
              placeholder='Type your password'
              name='user[password]'
              value={password}
              handlePasswordChanges={handlePasswordChanges}
            />
            <input
              type='hidden'
              name='user[password]'
              id='hiddenPassword'
              value={password}
              readOnly
            />
            <div className='flex flex-row text-14px'>
              Password strength:{' '}
              <div style={{ color: passwordStrengthColor }}>
                &nbsp;{password === '' ? 'No input' : pwStrength}
              </div>
            </div>

            <label className='pb-5px pt-20px' htmlFor='password-confirmation'>
              Retype your password
            </label>
            <ConfirmPasswordInput
              placeholder='Confirm your password'
              name='user[password_confirmation]'
              value={confirmPassword}
              handleConfirmChanges={handleConfirmPasswordChanges}
            />
            <input
              type='hidden'
              name='user[password_confirmation]'
              id='hiddenCOnfirmPassword'
              value={confirmPassword}
              readOnly
            />
            <div
              className='text-14px text-red-700'
              style={
                validPassword
                  ? { color: confirmPasswordStrengthColor }
                  : { color: '#B91C1C' }
              }>
              {confirmPassword === '' || password === ''
                ? 'Please fill up the password fields'
                : confirmPassword !== password
                ? 'Passwords Dont Match'
                : password.length <= 7 || confirmPassword.length <= 7
                ? 'Passwords match but less than 8 characters'
                : 'Passwords Match'}
            </div>
            <div className='pt-6 text-14px font-source-sans-pro font-weight-600'>
              Password must contain the following:
              <ul className='font-thin pl-36px'>
                <li
                  className={`${
                    conditionOne ? 'text-green-450' : 'text-black'
                  }`}>
                  At least 8 characters
                </li>
                <li
                  className={`${
                    conditionTwo ? 'text-green-450' : 'text-black'
                  }`}>
                  At least 1 number
                </li>
                <li
                  className={`${
                    conditionThree ? 'text-green-450' : 'text-black'
                  }`}>
                  At least 1 special character
                </li>
                <li
                  className={`${
                    conditionFour ? 'text-green-450' : 'text-black'
                  }`}>
                  At least 1 Uppercase Letter
                </li>
                <li
                  className={`${
                    conditionFive ? 'text-green-450' : 'text-black'
                  }`}>
                  At least 1 Lowercase Letter
                </li>
              </ul>
            </div>
            <div className='w-full pt-7 flex flex-row justify-center'>
              <button
                type='submit'
                disabled={!signupValid}
                className={`${
                  signupValid
                    ? 'w-138px h-45px bg-orange-650 text-white cursor-pointer rounded-md'
                    : 'w-138px h-45px text-gray-400 bg-gray-300 cursor-not-allowed'
                }`}>
                Sign Up
              </button>
            </div>
          </form>
          <div className='w-full pt-6 pb-61px text-16px flex flex-row justify-center font-source-sans-pro'>
            Already have an account?{' '}
            <div className='cursor-pointer pl-2'>
              <a href='/' className='text-orange-650 underline'>
                Login here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpComponent
